<template>
  <WeAppContainer>
    <WeCenter>
      <div class="card custom-header-bg">
        <div class="col text-center">
          <span class="big-text">404</span>
          <h3 class="mb-4">Aradığınız Sayfa Bulunamadı</h3>
          <router-link to="/" class="btn btn-primary mb-5">
            <i class="fas fa-home"></i> Anasayfa
          </router-link>
        </div>
      </div>
    </WeCenter>
  </WeAppContainer>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
